import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/blog/cve-2021-44228",
  "date": "12th December 2021",
  "title": "Log4Shell: CVE-2021-44228",
  "wip": true,
  "draft": true,
  "summary": "Skpr's response to to the Log4Shell Zero Day vulnerability",
  "author": "Nick Schuch",
  "tag": "Security",
  "tagColor": "yellow",
  "tags": [{
    "name": "cve"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`This blog post is for tracking the Skpr platform team's response to the Log4Shell Zero-Day vulnerability (`}<a parentName="p" {...{
        "href": "https://nvd.nist.gov/vuln/detail/CVE-2021-44228"
      }}>{`CVE-2021-44228`}</a>{`).`}</p>
    <h2>{`Affected Services`}</h2>
    <ul>
      <li parentName="ul">{`Solr`}</li>
      <li parentName="ul">{`OpenSearch (Elasticsearch)`}</li>
    </ul>
    <h2>{`Current Status`}</h2>
    <p>{`The Skpr platform team has `}<strong parentName="p">{`quickly responded with mitigations at both the firewall and service levels`}</strong>{`.`}</p>
    <p>{`We are tracking upstream changes to both Solr and OpenSearch projects for any additional mitigations or updates.`}</p>
    <h2>{`Details`}</h2>
    <h3>{`Solr`}</h3>
    <p>{`The Log4Shell behavior has been disabled using the environment variable `}<inlineCode parentName="p">{`LOG4J_FORMAT_MSG_NO_LOOKUPS=true`}</inlineCode>{` as per `}<a parentName="p" {...{
        "href": "https://www.docker.com/blog/apache-log4j-2-cve-2021-44228"
      }}>{`this Docker blog post`}</a>{`.`}</p>
    <p>{`The environment variable will remain until upstream Solr images ship with log4j2 2.15.0 which enables this behavior by default.`}</p>
    <h3>{`OpenSearch`}</h3>
    <p>{`The AWS WAF Managed Ruleset `}<strong parentName="p">{`AWSManagedRulesKnownBadInputsRuleSet`}</strong>{` has been applied to all affected clusters. This managed ruleset contains rules for explicitly blocking requests which exploit this vulnerability.`}</p>
    <p>{`All Opensearch services have received the R20211203-P2 patch which `}<a parentName="p" {...{
        "href": "https://aws.amazon.com/security/security-bulletins/AWS-2021-006/"
      }}>{`resolves the issue`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      